import React from "react";
import { Button, Modal } from 'react-bootstrap';

const Footer = () => {

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <footer className="text-start ms-2 pb-1" style={{ zIndex: 10, marginBottom: "1em", paddingLeft: "1em" }}>
      <div>
        <Button
          variant="outline-dark btn-sm btn-light rounded-pill "
          className="d-flex align-items-center"
          onClick={handleShow}
        >
          About
        </Button>
      </div>

      <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          centered
          >
        <Modal.Header closeButton>
          <Modal.Title>
          NonFungibleHomepage is a decentralized publicity grid of 100x100!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>You can mint tiles via a smartcontract. A tile is an NFT certifying your possession of the tile, then put an image on this tile via a smartcontract!</li>
            <li>You can trade or resell your tiles on NFT marketplaces.</li>
            <li>When you place an image on a tile you can link to your personal website, your business, your NFT collection, etc...</li>
            <li>Placing the same image on 4 square tiles will enlarge your image and improve its visibility!</li>
            <li>Mint a random tile costs 0.1 egld and a specific one costs 0.2 egld !</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark rounded-pill px-4" onClick={handleClose}>Ok</Button>
        </Modal.Footer>
      </Modal>

    </footer>
  );
};

export default Footer;
