import React, { useEffect, useState } from "react";
import { Address } from "@elrondnetwork/erdjs";
import * as Dapp from "@elrondnetwork/dapp";

import { Stage, Sprite } from "@inlet/react-pixi";
import * as PIXI from "pixi.js";
import empty from "assets/img/empty.png";

import { computeMaxSubMatrix } from "./algorithms";
import { fetchTiles, getNbNFT, getAddressNFT } from "./helpers/asyncRequests";
import { NFH } from "helpers/types";
import { decodeBase64 } from "helpers/function";

import SideInfos from "./SideInfos";
import {Point, TileType} from "./types";
import Viewport from "./Viewport";

import FileType from "file-type/browser";

import { collection } from "config";

const XMAX = 100;
const YMAX = 100;
const WIDTH = 50;
const OFFSET = WIDTH/2;
const HEIGHTWINDOW = window.innerHeight;
const WIDTHWINDOW = window.innerWidth;
// const texMap = {};
// const getTexture = async (src: string) => {
//   if (src in texMap && texMap[src]){
//     console.log("hit", src);
//     return texMap[src];
//   }
//   else {
//     console.log("miss", src);
//     const t = await PIXI.Texture.fromURL(src);
//     texMap[src] = t;
//     return t;
//   }
// };

const Map = () => {
  const { loggedIn, address } = Dapp.useContext();
  const [myTile, setMyTile] = useState<{ [key: string]: NFH }>({});
  const [tiles, setTiles] = useState<{ [key: string]: TileType }>({});
  const [loading, setLoading] = useState<string>("Initialisation");
  const [minted, setMinted] = useState<number>(0);
  const [go, setGo] = useState<boolean>(false);
  const base = PIXI.Texture.from(empty);
  const errorImg = "https://bafkreia2kfemgds6qifulyqmo5z3kcgpwqhfjoc6t67h3hkjscddbmafei.ipfs.dweb.link/";

  

  

  useEffect(() => {
    const fetchUserNFT = async () => {
      let nbNFT = 10000;
      const size = await getNbNFT(address);
      if (size.success && size.data){
        nbNFT = size.data;
      }

      //console.log("nbNFT: "+nbNFT);

      const resEsdts = await getAddressNFT(address,0,nbNFT);
      const addressTile: { [key: string]: NFH } = {};
      if (resEsdts.success && resEsdts.data){
        for (let i = 0; i < resEsdts.data.length; i++){
          if (resEsdts.data[i].collection === collection){
            const coord = decodeBase64(resEsdts.data[i].attributes).split(";");
            const x = coord[0].substring(2);
            const y = coord[1].substring(2);
            const nft = {
                x: x,
                y: y,
                name: resEsdts.data[i].name,
                nonce: resEsdts.data[i].nonce,
                url: decodeBase64(resEsdts.data[i].uris[0]),
              };
            addressTile[`${x}x${y}`] = nft;
          }
        }
      }
      setMyTile(addressTile);
      //console.log("mytiles : "+Object.keys(addressTile).length);

    };
    if (loggedIn){
      fetchUserNFT();
    }
  }, [loggedIn, address]);

  async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 8000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);
    return response;
  }

  useEffect(() => {

    const populateTiles = async () => {
      setLoading("Fetching data");
      let raw = await fetchTiles();
      raw = raw.data.data.data.returnData[0];

      const buffer = Buffer.from(raw, "base64");
      let bufString = buffer.toString("hex");

      const every_nft: { [key: string]: TileType } = {};

      let nbImage = 0;
      while (bufString.length > 0) {
        /*
        * Parsing payload (b64 encoded) :
        *   | x[2B] | y[2B] | owner[64B] | (0*) nft uri(.+) ("00"$) | (0*) href(.+) ("00"$) |
        */

        const x = Number("0x" + bufString.substring(0, 2)); bufString = bufString.substring(2);
        const y = Number("0x" + bufString.substring(0, 2)); bufString = bufString.substring(2);

        const owner = bufString.substring(0, 64);
        bufString = bufString.substring(64);

        while (bufString.length > 0 && bufString[0] === "0" && bufString[1] === "0") bufString = bufString.substring(2);

        const eof = bufString.indexOf("00");
        let hexanft = bufString.substring(0, eof);

        //if (hexanft.length%2 === 1){hexanft += "0";}
        let nft = errorImg;
        try{
          nft = Buffer.from(hexanft, "hex").toString().substring(1);
          /*if (!nft.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)){
            //nft = "https://nonfungiblehomepage.org/logo.png"
          }*/
          //console.log("Success Image x:"+x+" y:"+y);
        }catch(e){
          nft = errorImg;
          console.log("Error Image x:"+x+" y:"+y);
          console.log(hexanft);
        }

        bufString = bufString.substring(eof);

        while (bufString.length > 0 && bufString[0] === "0" && bufString[1] === "0") bufString = bufString.substring(2);
        const eof2 = bufString.indexOf("00");

        let hexahref = bufString.substring(0, eof2);
        //if (hexahref.length%2 === 1){hexahref += "0";}
        let href = "#";
        try{
          href = Buffer.from(hexahref, "hex").toString().substring(1);
          /*if (!href.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)){
            href = "#"
          }*/
        }catch(e){
          href = "#";
          console.log("Error href x:"+x+" y:"+y);
          console.log(hexahref);
        } 
        bufString = bufString.substring(eof2);

        if (nft) {
          if ( !(`${owner}${nft}` in every_nft) )
            every_nft[`${owner}${nft}`] = {
              points: {},
              x: -1,
              y: -1,
              nft,
              owner,
              href,
            };

          every_nft[`${owner}${nft}`].points[`${x}x${y}`] = true;
          nbImage++;
        }

        if (bufString.length > 0 && bufString[0] === "0" && bufString[1] === "0")
          bufString = bufString.substring(2);
        else
          break;
      }
      console.log("N : "+nbImage);
      setMinted(nbImage);
      setLoading("Calculating the matrix");
      let limit = 0;
      const final: { [key: string]: TileType } = {};
      for (const n in every_nft) {

        // rm invalid points
        if (n)
          for (let i in every_nft[n].points) {
            let [x, y] = i.split("x");
            if ( 0 > Number(x) || Number(x) > 100 || 0 > Number(y) || Number(y) > 100 ) {
              console.log("aborted", `${x}x${y}`)
              delete every_nft[n].points[`${x}x${y}`];
            }
          }

        while (n && Object.keys(every_nft[n].points).length > 0 && limit < 1000) {
          limit++;
          try{
            const r = computeMaxSubMatrix(every_nft[n].points);
            final[`${r.x}x${r.y}`] = {
              x: r.x,
              y: r.y,
              w: r.w,
              nft: every_nft[n].nft,
              owner: new Address(every_nft[n].owner).toString(),
              href: every_nft[n].href,
            };

            for (let i = r.x; i <= r.x + r.w; i++)
              for (let j = r.y; j <= r.y + r.w; j++) {
                delete every_nft[n].points[`${i}x${j}`];
              }
          }catch(error){
            console.error(error);
          }
        }
      }

      setLoading("Checking URLs");
      let indice = Object.keys(final);
      const alreadyFetch = [];

      let i = 0;
      const promises = [];

      while (i < indice.length){
        if (final[indice[i]].nft !== errorImg){
          //promises.push(fetchWithTimeout(final[indice[i]].nft,{timeout: 3000}));
        }
        alreadyFetch.push(final[indice[i]].nft);
        i++;
      }

      const result = await Promise.allSettled(promises);
      result.map( async (promise,n) => {
        if (promise.status === "fulfilled"){
          const fileType = await FileType.fromStream(promise.value.body);
          if (fileType != null && fileType.mime) {
            const ft = fileType.mime.match(/(?:(?!\/).)*/)
            if (!(ft && ft.length && ft[0] === "image")){
              final[indice[n]].nft = errorImg;
            }
          }
        }else{
          final[indice[n]].nft = errorImg;
          //console.log("CORS x:"+final[indice[n]].x+" y:"+final[indice[n]].y);
        }
      });
      

      setLoading("");
      setTiles(final);
      //console.log("END")

    };

    populateTiles();
  }, []);

  const [selected, setSelected] = useState<{point: Point, tile?: TileType}>();

  if (Object.keys(tiles).length !== 0)
    return (
      <div className="container m-0 p-0">
        <div 
          style={{zIndex: "100"}}
          className="position-absolute end-0 p-2 m-2 rounded-pill bg-light fw-bold">
        {minted} / 10,000 minted
        </div>
        <Stage
          style={{
            position: "absolute",
            top: 0,
          }}
          width={window.innerWidth}
          height={window.innerHeight}
          options={{
            backgroundColor: 0xFFFFFF,
            clearBeforeRender: false,
          }}
          raf={true}
        >
          <Viewport
            width={WIDTHWINDOW}
            height={HEIGHTWINDOW}
          >
            {
              Array.from(Array(XMAX).keys()).map((x) =>
                Array.from(Array(YMAX).keys()).map((y)=> (
                  <Sprite
                    key={`${x}x${y}`}
                    texture={base}
                    width={WIDTH}
                    height={WIDTH}
                    x={x*50 + OFFSET}
                    y={y*50 + OFFSET}
                    interactive
                    buttonMode
                    click={() => {
                      if (!window.drawing) {
                        setSelected({"point": {x, y}, "tile": tiles[x+"x"+y]});
                      }
                    }}
                    tap={() => {
                      if (!window.drawing) {
                        setSelected({"point": {x, y}, "tile": tiles[x+"x"+y]});
                      }
                    }}
                  />
                )
              ))
            }
            {
              Object.keys(tiles).map((t,n) => {
                return <Sprite
                  key={"t"+t}
                  image={errorImg}
                  width={(tiles[t].w + 1) * WIDTH}
                  height={(tiles[t].w + 1) * WIDTH}
                  x={tiles[t].x*50 + OFFSET}
                  y={tiles[t].y*50 + OFFSET}
                  interactive
                  buttonMode
                  click={() => {
                    if (!window.drawing) {
                      setSelected({"point": {x: tiles[t].x, y: tiles[t].y}, "tile": tiles[t]});
                    }
                  }}
                  tap={(e) => {
                    if (!window.drawing) {
                      setSelected({"point": {x: tiles[t].x, y: tiles[t].y}, "tile": tiles[t]});
                    }
                  }}
                />
              })
            }
            {
              Object.keys(tiles).map((t,n) => {
                return <Sprite
                  key={"t"+t}
                  image={tiles[t].nft}
                  width={(tiles[t].w + 1) * WIDTH}
                  height={(tiles[t].w + 1) * WIDTH}
                  x={tiles[t].x*50 + OFFSET}
                  y={tiles[t].y*50 + OFFSET}
                  interactive
                  buttonMode
                  click={() => {
                    if (!window.drawing) {
                      setSelected({"point": {x: tiles[t].x, y: tiles[t].y}, "tile": tiles[t]});
                    }
                  }}
                  tap={(e) => {
                    if (!window.drawing) {
                      setSelected({"point": {x: tiles[t].x, y: tiles[t].y}, "tile": tiles[t]});
                    }
                  }}
                />
              })
            }
          </Viewport>
        </Stage>

        <SideInfos selected={selected} close={setSelected} myTile={myTile}/>

      </div>
    );
  else return (
    <div className="fs-1 text-center h-100 my-auto">
      {loading !== "" && (
        <>
        <div className="spinner-border text-secondary" style={{width: "2em", height: "2em"}} role="status">
          <span className="visually-hidden">Loading ...</span>
        </div>
        <br/>
        <span className="text-dark fw-bold fs-3">{loading}</span>
        </>
      )}
      {Object.keys(tiles).length !== 0 && loading === "" && (
        <button className="btn btn-outline-dark btn-lg fw-bold" onClick={() => setGo(true)}>Start exploring</button>
      )}
    </div>
    );
};

export default Map;
