import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { NavItem, Nav, Modal, Form, Button } from "react-bootstrap";
import { IoMdLogOut, IoMdLogIn } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import { routeNames } from "routes";
import { contractAddress } from "config";

import { RawTransactionType } from "helpers/types";
import { valToHex, stringToHex } from "helpers/function";
import useNewTransaction from "pages/Transaction/useNewTransaction";

const Navbar = () => {
  const { loggedIn } = Dapp.useContext();
  const sendTransaction = Dapp.useSendTransaction();
  const newTransaction = useNewTransaction();

  const homePage = window.location.pathname === routeNames.map;
  const profilePage = window.location.pathname === routeNames.profile;
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push("/");
  };

  const send = (qt: number, url: string) => {
    if (qt > 0 && qt <= 100){
      let dataTransaction = "mintRandomTile@"+valToHex(qt);
      if (url !== ""){dataTransaction += "@"+stringToHex(url);}
      const randomTransaction: RawTransactionType = {
        receiver: contractAddress,
        data: dataTransaction,
        value: Number(0.1*qt).toFixed(1)+"",
        gasLimit: Math.min((20000000*qt),600000000),
      };

      sendTransaction({
        transaction: newTransaction(randomTransaction),
        callbackRoute: routeNames.map,
      });
    }
  };

  const [quantity, setQuantity] = React.useState<number>(1);
  const [urlImage, setUrlImage] = React.useState<string>("");
  const [btnText, setBtnText] = React.useState<string>("Confirm");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [showMint, setShowMint] = React.useState(false);
  const handleClose = () => setShowMint(false);
  const handleShow = () => setShowMint(true);

  const handleSubmit = () => {
    setIsSubmitting(true);
    setBtnText("Loading");
    send(quantity,urlImage);
    setIsSubmitting(false);
    setBtnText("Confirm");
    handleClose();
  };

  const handleChange = (event) => {
    if (event.target.name === "Quantity")
      setQuantity(parseInt(Number(event.target.value).toFixed(1)));

    if (event.target.name === "Url")
      setUrlImage(event.target.value);
  };

  return (
    <Nav
      className="ml-auto"
      style={{
        position: "absolute",
        top: "1em",
        left: "2em",
        zIndex: 10,
      }}
    >
      {loggedIn ? (
        <NavItem>
          <button
            onClick={logOut}
            className="btn btn-light border-1 border-dark mt-3 rounded-circle p-2 me-2"
          >
             <IoMdLogOut size="2em"/>
          </button>
          {homePage && (
            <button
            onClick={handleShow}
            className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4 me-2"
          >
            Mint random
          </button>
          )}

          {!profilePage && 
            <Link
              to={routeNames.profile}
              className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4"
              data-testid="loginBtn"
            >
              My tiles
            </Link>
          }
          {!homePage && 
            <Link
              to={routeNames.map}
              className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4 ms-2"
            >
              Home
            </Link>
          }
        </NavItem>
      ) : (
        <NavItem>
          {homePage &&
            <>
              <Link
                to={routeNames.unlock}
                className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-3 me-2"
                data-testid="loginBtn"
              >
                Login <IoMdLogIn size="1.45em"/>
              </Link>
              <Link
                to={routeNames.unlock}
                className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4"
                data-testid="loginBtn"
              >
                Mint random
              </Link>
            </>
          }
          {!homePage && 
            <Link
              to={routeNames.map}
              className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4 ms-2"
            >
              Back
            </Link>
          }
        </NavItem>
      )}

      

      <Modal
          show={showMint}
          onHide={handleClose}
          size="lg"
          centered
          >
        <Modal.Header closeButton>
          <Modal.Title>Mint random tiles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center fs-4 fw-bold">
            Total cost : {Number(0.1*quantity).toFixed(1)} EGLD
          </div>
          <Form style={{maxWidth: "300px"}} className="mx-auto">
            <Form.Group controlId="validationFormik01" className="my-1 mt-4 w-auto  mx-auto">
                <Form.Label className="text-center w-100">Amount of tiles<span className="text-danger">*</span> :</Form.Label>
                <Form.Control 
                  type="number" placeholder="1" 
                  min="1" max="100"
                  className="rounded shadow-sm text-center"
                  name="Quantity"
                  onChange={handleChange}
                  value={quantity}
                />
            <div className="text-danger small">The quantity must be between 0 and 100</div>
            </Form.Group>

            <Form.Group controlId="validationFormik01" className="my-1 mt-2 w-auto mx-auto">
                <Form.Label className="text-center w-100">Your image URL :</Form.Label>
                <Form.Control 
                  type="text" placeholder="https://...." 
                  className="rounded shadow-none"
                  name="Url"
                  onChange={handleChange}
                  value={urlImage}
                />
            <div className="text-muted small">Optional</div>
            </Form.Group>

          </Form>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>{btnText}</Button>
        </Modal.Footer>
      </Modal>
    </Nav>
  );
};

export default Navbar;
