import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dAppName } from "config";
import { routeNames } from "routes";
import { Address } from "@elrondnetwork/erdjs";

import { AiOutlineLink } from "react-icons/ai";

import { Image, Button, Modal, Form } from 'react-bootstrap';

import * as Dapp from "@elrondnetwork/dapp";
import { Ui } from "@elrondnetwork/dapp-utils";

import { NFH } from "helpers/types";
import { fetchTiles, getNbNFT, getAddressNFT } from "../Map/helpers/asyncRequests";

import { contractAddress, collection } from "config";
import { RawTransactionType } from "helpers/types";
import useNewTransaction from "pages/Transaction/useNewTransaction";
import { decodeBase64,valToHex,stringToHex } from "helpers/function";

const Profile = () => {
  const { loggedIn, address, explorerAddress } = Dapp.useContext();
  const [myTile, setMyTile] = useState<{ [key: string]: NFH }>({});
  const [myTileContent, setMyTileContent] = useState<{ [key: string]: string }>({});
  const [myTileHref, setMyTileHref] = useState<{ [key: string]: string }>({});

  const sendTransaction = Dapp.useSendTransaction();
  const newTransaction = useNewTransaction();

  const [editSelected, setEditSelected] = React.useState<NFH>();
  const [link, setLink] = React.useState<string>("");
  const [urlImage, setUrlImage] = React.useState<string>("");
  const [btnText, setBtnText] = React.useState<string>("Confirm");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const handleClose = () => setShowEdit(false);
  const handleShow = () => setShowEdit(true);

  const handleSubmit = () => {
    setIsSubmitting(true);
    setBtnText("Loading");
    if (editSelected){
      sendEdit(editSelected.nonce,urlImage,link);
    }
    setIsSubmitting(false);
    setBtnText("Confirm");
  };

  const handleChange = (event) => {
    if (event.target.name === "Link")
      setLink(event.target.value);
    if (event.target.name === "Url")
      setUrlImage(event.target.value);
  };

  const sendEdit = (nonce: number,url: string, link: string) => {
    const encodeAddress = new Address(contractAddress);
    let dataTransaction;
    if (link === ""){
      dataTransaction = "ESDTNFTTransfer@"+stringToHex(collection)+"@"+valToHex(nonce)+"@"+valToHex(1)+"@"+encodeAddress.hex()+"@"+stringToHex("putImage")+"@"+stringToHex(url);
    }else{
      dataTransaction = "ESDTNFTTransfer@"+stringToHex(collection)+"@"+valToHex(nonce)+"@"+valToHex(1)+"@"+encodeAddress.hex()+"@"+stringToHex("putImage")+"@"+stringToHex(url)+"@"+stringToHex(link);
    }
    const editTransaction: RawTransactionType = {
      receiver: address,
      data: dataTransaction,
      value: "0",
      gasLimit: 10000000,
    };

    sendTransaction({
      transaction: newTransaction(editTransaction),
      callbackRoute: routeNames.profile,
    });
  };

  useEffect(() => {

    const populateTiles = async () => {
      let raw = await fetchTiles();
      raw = raw.data.data.data.returnData[0];

      const buffer = Buffer.from(raw, "base64");
      let bufString = buffer.toString("hex");

      const every_nft: { [key: string]: TileType } = {};
      const every_href: { [key: string]: TileType } = {};


      let nbImage = 0;
      const errorImg = "https://bafkreia2kfemgds6qifulyqmo5z3kcgpwqhfjoc6t67h3hkjscddbmafei.ipfs.dweb.link/";
      while (bufString.length > 0) {
        /*
        * Parsing payload (b64 encoded) :
        *   | x[2B] | y[2B] | owner[64B] | (0*) nft uri(.+) ("00"$) | (0*) href(.+) ("00"$) |
        */

        const x = Number("0x" + bufString.substring(0, 2)); bufString = bufString.substring(2);
        const y = Number("0x" + bufString.substring(0, 2)); bufString = bufString.substring(2);

        const owner = bufString.substring(0, 64);
        

        bufString = bufString.substring(64);
        while (bufString.length > 0 && bufString[0] === "0" && bufString[1] === "0") bufString = bufString.substring(2);
        const eof = bufString.indexOf("00");
        
        let hexanft = bufString.substring(0, eof);
        let nft = errorImg;
        try{ nft = Buffer.from(hexanft, "hex").toString().substring(1);
        }catch(e){nft = errorImg;console.log("Error Image x:"+x+" y:"+y);}

        bufString = bufString.substring(eof);
        while (bufString.length > 0 && bufString[0] === "0" && bufString[1] === "0") bufString = bufString.substring(2);
        const eof2 = bufString.indexOf("00");

        let hexahref = bufString.substring(0, eof2);
        let href = "#";
        try{ href = Buffer.from(hexahref, "hex").toString().substring(1);
        }catch(e){href = "#";console.log("Error href x:"+x+" y:"+y);} 
        bufString = bufString.substring(eof2);

        if (nft && (new Address(owner)).toString() === address) {
          every_nft[`${x}x${y}`] = nft;
          every_href[`${x}x${y}`] = href;
          nbImage++;
        }
        
        if (bufString.length > 0 && bufString[0] === "0" && bufString[1] === "0")
          bufString = bufString.substring(2);
        else
          break;

      }
      setMyTileContent(every_nft);
      setMyTileHref(every_href)
    }

    const fetchUserNFT = async () => {
      let nbNFT = 10000;
      const size = await getNbNFT(address);
      if (size.success && size.data){
        nbNFT = size.data;
      }

      const resEsdts = await getAddressNFT(address,0,nbNFT);
      const addressTile: { [key: string]: NFH } = {};
      if (resEsdts.success && resEsdts.data){
        for (let i = 0; i < resEsdts.data.length; i++){
          if (resEsdts.data[i].collection === collection){
            const coord = decodeBase64(resEsdts.data[i].attributes).split(";");
            const x = coord[0].substring(2);
            const y = coord[1].substring(2);
            const nft = {
                x: x,
                y: y,
                name: resEsdts.data[i].name,
                nonce: resEsdts.data[i].nonce,
                url: decodeBase64(resEsdts.data[i].uris[0]),
              };
            addressTile[`${x}x${y}`] = nft;
          }
        }
      }
      setMyTile(addressTile);
    };

    if (loggedIn){
      fetchUserNFT();
      populateTiles();
    }
  }, [loggedIn, address]);

  return (
    <div className="d-flex flex-fill align-items-center container mt-5 pt-5">
      <div className="w-100">
        <h2 className="mb-3" data-testid="title">
          Profile
        </h2>
        <a
          className="text-dark"
          href={`${explorerAddress}accounts/${address}`}
          {...{
            target: "_blank",
          }}
          title="View in Explorer"
        >
          <Ui.Trim  text={address} />
        </a>
        { Object.keys(myTile).length !== 0 && Object.keys(myTileContent).length !== 0 && Object.keys(myTileHref).length !== 0 && (
          <div className="row mt-3"> {

            Object.keys(myTile).map((t,n) => {
              return <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-2" key={t}>
                      <div className="shadow rounded p-2">
                        <Image fluid src={myTileContent[t]} />
                        <div className="text-truncate" style={{overflow: "hidden"}}>
                           <AiOutlineLink />&nbsp;
                           <a href={myTileHref[t]}  className="link-dark" target="_blank" rel="noreferrer">
                            {myTileHref[t]}
                          </a>
                        </div>
                        <div className="d-flex mt-2">
                          <span className="text-dark fs-4 fw-bold me-auto">{myTile[t].name}</span>
                          <Button 
                            className="rounded-pill"
                            onClick={() => {handleShow();setEditSelected(myTile[t])}}
                            >
                            Edit Image
                            </Button>
                        </div>
                      </div>

                    </div>;
            })
          }
          </div>)
        }

        { Object.keys(myTile).length === 0 && (
          <div className="d-flex flex-fill align-items-center container" style={{height: "300px"}}>
          <div className="spinner-border text-secondary" style={{width: "2em", height: "2em"}} role="status">
            <span className="visually-hidden">Loading ...</span>
          </div>
          <span className="ms-2 fw-bold fs-4">Loading</span>
          </div>
          )
        }
      </div>

      <Modal
        show={showEdit}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit tile : {editSelected ? editSelected.name : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{maxWidth: "300px"}} className="mx-auto">
            <Form.Group controlId="validationFormik01" className="my-1 mt-4 w-auto  mx-auto">
                <Form.Label className="text-center w-100">Image URL<span className="text-danger">*</span> :</Form.Label>
                <Form.Control 
                  type="text" placeholder="https://...." 
                  className="rounded shadow-none"
                  name="Url"
                  onChange={handleChange}
                  value={urlImage}
            autoComplete="off"
                />
            </Form.Group>

            <Form.Group controlId="validationFormik01" className="my-1 mt-2 w-auto  mx-auto">
                <Form.Label className="text-center w-100">Link</Form.Label>
                <Form.Control 
                  type="text" placeholder="https://...." 
                  className="rounded shadow-none"
                  name="Link"
                  onChange={handleChange}
                  value={link}
            autoComplete="off"
                />
                <div className="text-muted small">Optional</div>
            </Form.Group>
          </Form>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>{btnText}</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Profile;
