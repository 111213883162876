import React from "react";
import { Ui } from "@elrondnetwork/dapp-utils";
import { Offcanvas, Navbar, Container, Image, Accordion, ListGroup, Modal, Button, Form } from "react-bootstrap";
import { AiOutlineLink } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { RiMapPin2Line } from "react-icons/ri";
import { CgArrowsExpandRightAlt } from "react-icons/cg";
import img from "assets/img/empty.png";
import * as Dapp from "@elrondnetwork/dapp";
import {Address} from "@elrondnetwork/erdjs";

import { contractAddress, collection } from "config";
import { RawTransactionType } from "helpers/types";
import useNewTransaction from "pages/Transaction/useNewTransaction";
import { valToHex,stringToHex } from "helpers/function";
import { routeNames } from "routes";
import { Link } from "react-router-dom";

import { Point, TileType, NFH } from "helpers/types";

import { useMediaQuery } from "react-responsive";


interface SideInfosProps {
	selected?: {point: Point, tile?: TileType};
	locked?: boolean;
	close: (arg0?:{point: Point, tile?: TileType}) => void;
	myTile: { [key: string]: NFH };
};

const SideInfos = ({selected, close, myTile}: SideInfosProps) => {

	const isSmallDick = useMediaQuery({ query: "(max-width: 600px)" });

	const { address, loggedIn } = Dapp.useContext();
  	const sendTransaction = Dapp.useSendTransaction();
  	const newTransaction = useNewTransaction();

  	const sendMint = (x: number, y: number) => {
	    const randomTransaction: RawTransactionType = {
	      receiver: contractAddress,
	      data: "mintTile@"+valToHex(x)+"@"+valToHex(y),
	      value: (0.2)+"",
	      gasLimit: 20000000,
	    };

	    sendTransaction({
	      transaction: newTransaction(randomTransaction),
	      callbackRoute: routeNames.map,
	    });
	};

	const sendEdit = (nonce: number,url: string, link: string) => {
		const encodeAddress = new Address(contractAddress);
		let dataTransaction;
		if (link === ""){
			dataTransaction = "ESDTNFTTransfer@"+stringToHex(collection)+"@"+valToHex(nonce)+"@"+valToHex(1)+"@"+encodeAddress.hex()+"@"+stringToHex("putImage")+"@"+stringToHex(url);
		}else{
			dataTransaction = "ESDTNFTTransfer@"+stringToHex(collection)+"@"+valToHex(nonce)+"@"+valToHex(1)+"@"+encodeAddress.hex()+"@"+stringToHex("putImage")+"@"+stringToHex(url)+"@"+stringToHex(link);
		}

	    const editTransaction: RawTransactionType = {
	      receiver: address,
	      data: dataTransaction,
	      value: "0",
	      gasLimit: 10000000,
	    };

	    sendTransaction({
	      transaction: newTransaction(editTransaction),
	      callbackRoute: routeNames.map,
	    });
	};
	const { explorerAddress } = Dapp.useContext();

	const [editSelected, setEditSelected] = React.useState<NFH>();

	const [link, setLink] = React.useState<string>("");

	const [urlImage, setUrlImage] = React.useState<string>("");
	const [btnText, setBtnText] = React.useState<string>("Confirm");
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

	const [showEdit, setShowEdit] = React.useState(false);
	const handleClose = () => setShowEdit(false);
	const handleShow = () => setShowEdit(true);

	const handleSubmit = () => {

		setIsSubmitting(true);
		setBtnText("Loading");

		//console.log(urlImage);
		//console.log(link);

		if (editSelected){
			sendEdit(editSelected.nonce,urlImage,link);
		}
		setIsSubmitting(false);
		setBtnText("Confirm");

	};

	const handleChange = (event) => {
		if (event.target.name === "Link")
			setLink(event.target.value);
		if (event.target.name === "Url")
			setUrlImage(event.target.value);
	};

	const asTileIn = (x:number, y:number, w:number) => {
		let as = false;
		Array.from(Array(w+1).keys(), a => a + x).map((a) => {

			Array.from(Array(w+1).keys(), b => b + y).map((b) => {
				if (myTile[`${a}x${b}`]){ 
					as = true; 
				}
				return 1;
			});
			return 1;
		});
		return as;
	};


	if (selected) return (
		<Offcanvas
			show={!!selected}
			onHide={() => close()}
			placement={isSmallDick ? "bottom" : "end"}
			style={isSmallDick ? {height: "100vh",overflowY: "auto"} : { overflowY: "auto" }}
			scroll={!isSmallDick}
			backdrop={false}
		>
			<div className="card shadow-none border-0">
				<div className="card-header no-border row m-2 flex">
					<Navbar>
						<Container>
							<Navbar.Brand>
								<RiMapPin2Line className="m-1"/>
								[{selected.point.x}, {selected.point.y}]
								{
									(selected.tile && selected.tile.w > 0) &&
									<span>
										<CgArrowsExpandRightAlt size="1.8rem" className="py-2"/>
										[{selected.point.x + selected.tile.w}, {selected.point.y + selected.tile.w}]
									</span>
								}
							</Navbar.Brand>
							<Navbar.Toggle />
							<Navbar.Collapse className="justify-content-end">
								<Navbar.Text>
									<button
										className="col-md-2 float-right btn rounded-circle"
										onClick={() => close()}
									>
										<GrClose />
									</button>
								</Navbar.Text>
							</Navbar.Collapse>
						</Container>
					</Navbar>
				</div>
				<div className="card-body pt-0">
					<div className="widget-49">
						<div className="widget-49-title-wrapper">
							<div className="widget-49-meeting-info container-fluid">
								{selected.tile
									? (
										<>
											<Image fluid src={selected.tile.nft} />
											<div className="widget-49-pro-title mt-4">
												Tile owned by
												<div
													className="flex rounded-lg bg-light rounded-lg p-2 link"
													onClick={() => window.open(`${explorerAddress}accounts/${selected.tile.owner}`, "_blank")}
												>
													<Ui.Trim text={selected.tile.owner} />
												</div>

												{(selected.tile.href && selected.tile.href !== "#") &&
													<span>
														<a href={selected.tile.href} className="link-dark" target="_blank" rel="noreferrer">{selected.tile.href}</a>
														<AiOutlineLink />
													</span>
												}
											</div>
										</>
									)
									: (
										<>
											<Image fluid src={img} />
											<h2 className="widget-49-pro-title">This place is for sale !</h2>
										</>
									)
								}
							</div>
						</div>
						{!selected.tile && loggedIn &&
							<div className="widget-49-meeting-action mt-4">
								<button onClick={() => {sendMint(selected.point.x, selected.point.y);}} className="btn btn-dark badge-pill p-2 px-4">GET THIS TILE FOR 0.2 EGLD</button>
							</div>
						}
						{!selected.tile && !loggedIn && 
							<div className="widget-49-meeting-action mt-4">
								<Link
									to={routeNames.unlock}
									className="btn btn-dark badge-pill p-2 px-4"
									data-testid="loginBtn"
								>
									GET THIS TILE FOR 0.2 EGLD
								</Link>
							</div>
						}

						{selected.tile && (
							(selected.tile.w === 0 && myTile[`${selected.point.x}x${selected.point.y}`]) ||
							(selected.tile.w > 0 && asTileIn(selected.point.x,selected.point.y,selected.tile.w)))
							&& (
							<Accordion defaultActiveKey="0" flush className="mt-4">
							  <Accordion.Item eventKey="0">
							    <Accordion.Header>My tiles :</Accordion.Header>
							    <Accordion.Body className="px-0">
							    	<ListGroup>
								      	{(selected.tile.w === 0) && myTile[`${selected.point.x}x${selected.point.y}`] &&
								      		<ListGroup.Item key={`${selected.point.x}x${selected.point.y}`}>
								      			<div className="d-flex">
													<div>{myTile[`${selected.point.x}x${selected.point.y}`].name}</div>
													<div className="ms-auto">
														<Button variant="outline-dark btn-sm" onClick={() => {handleShow();setEditSelected(myTile[`${selected.point.x}x${selected.point.y}`]);}}>
													        Edit
													    </Button>
													</div>
												</div>
								      		</ListGroup.Item>
										}

										{ (selected.tile.w > 0) && 
											Array.from(Array(selected.tile.w+1).keys(), x => x + selected.point.x).map((x) => (
												Array.from(Array(selected.tile.w+1).keys(), y => y + selected.point.y).map((y) => {
													if (!myTile[`${x}x${y}`]){ return ""; }

													return <ListGroup.Item key={`${x}x${y}`}>
														<div className="d-flex">
															<div>{myTile[`${x}x${y}`].name}</div>
															<div className="ms-auto">
																<Button variant="outline-dark btn-sm" onClick={() => {handleShow();setEditSelected(myTile[`${x}x${y}`]);}}>
															        Edit
															    </Button>
															</div>
														</div>
														</ListGroup.Item>;
												})
											))
										}
									</ListGroup>
							    </Accordion.Body>
							  </Accordion.Item>
							</Accordion>
						)}
					</div>
				</div>
			</div>
			<Modal
		        show={showEdit}
		        onHide={handleClose}
		        size="lg"
			    centered
		      >
		        <Modal.Header closeButton>
		          <Modal.Title>Edit tile : {editSelected ? editSelected.name : ""}</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form style={{maxWidth: "300px"}} className="mx-auto">
				        <Form.Group controlId="validationFormik01" className="my-1 mt-4 w-auto  mx-auto">
				            <Form.Label className="text-center w-100">Image URL<span className="text-danger">*</span> :</Form.Label>
				            <Form.Control 
				              type="text" placeholder="https://...." 
				              className="rounded shadow-none"
				              name="Url"
				              onChange={handleChange}
				              value={urlImage}
					      autoComplete="off"
				            />
				        </Form.Group>

				        <Form.Group controlId="validationFormik01" className="my-1 mt-2 w-auto  mx-auto">
				            <Form.Label className="text-center w-100">Link</Form.Label>
				            <Form.Control 
				              type="text" placeholder="https://...." 
				              className="rounded shadow-none"
				              name="Link"
				              onChange={handleChange}
				              value={link}
					      autoComplete="off"
				            />
				            <div className="text-muted small">Optional</div>
				        </Form.Group>
		        	</Form>
		          
		        </Modal.Body>
		        <Modal.Footer>
		          <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
		          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>{btnText}</Button>
		        </Modal.Footer>
		      </Modal>
		</Offcanvas>
	);

	else return null;
};

export default SideInfos;
