import axios from "axios";
import { network, contractAddress, collection } from "config";

export const fetchTiles = async () => {
  try {
    const { data } = await axios.post(`${network.gatewayAddress}/vm-values/query`, {
      scAddress: contractAddress,
      funcName: "getMapTile",
      args: [],
    });
    return {
      data: data,
      success: data !== undefined,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
    };
  }
};

export const getAddressNFT = async (address,from,size) => {
  try {
      const { data } = await axios.get(network.apiAddress+"/accounts/"+address+"/nfts?from="+from+"&size="+size+"&type=NonFungibleESDT&collection="+collection);
      return {success:true,data:data};
  } catch (e) {/*console.log(e);*/return {success:false};}
};

export const getNbNFT = async (address) => {
  try {
      const { data } = await axios.get(network.apiAddress+"/accounts/"+address+"/nfts/count?&type=NonFungibleESDT&collection="+collection);
      return {success:true,data:data};
  } catch (e) {/*console.log(e);*/return {success:false};}
};


/*
interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  contractAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    address,
    contractAddress,
    timeout,
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          sender: address,
          receiver: contractAddress,
          condition: "must",
          size: 25,
        },
        timeout,
      });

      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

export const getTransactions = fetchTransactions("/transactions");
export const getTransactionsCount = fetchTransactions("/transactions/count");
*/
