import React from "react";
import { PixiComponent, useApp } from "@inlet/react-pixi";
import { Viewport as PixiViewport } from "pixi-viewport";
import * as PIXI from "pixi.js";

export interface ViewportProps {
  width: number;
  height: number;
  children?: React.ReactNode;
  //dragging: (arg0:boolean) => void;
}

declare global {
    interface Window { drawing: boolean; }
}

export interface PixiComponentViewportProps extends ViewportProps {
  app: PIXI.Application;
}


const PixiComponentViewport = PixiComponent("Viewport", {
  create: (props: PixiComponentViewportProps) => {

    const viewport = new PixiViewport({
      screenWidth: props.width,
      screenHeight: props.height,
      worldWidth: 100*50,
      worldHeight: 100*50,
      ticker: props.app.ticker,
      interaction: props.app.renderer.plugins.interaction,
      disableOnContextMenu: true,
    });

    viewport.drag().pinch().wheel().decelerate()
    .clampZoom({
      minWidth: 75, minHeight: 75,
      maxWidth: 200*100, maxHeight: 200*100,
      minScale: 2, maxScale: 200})
    .clamp({
      left: -200*50, right: 100*150,
      top: -200*50, bottom: 100*150
    })
    .snapZoom({width: 100*30, forceStart:true, removeOnComplete: true})
    .snap(50*100/2, 50*100/2, {time: 0, interrupt: false, removeOnComplete: true});
    //.ensureVisible

    viewport.on("drag-start", () => window.drawing = true);
    viewport.on("drag-end", () => window.drawing = false);

    viewport.on("zoomed", () => {window.drawing = true; });
    viewport.on("zoomed-end", () => { setTimeout(() => window.drawing = false, 150); });

    window.onresize = () => {
        viewport.resize(window.innerWidth, window.innerHeight)
        props.app.renderer.resize(window.innerWidth, window.innerHeight);
    }
    return viewport;
  }
});

const Viewport = (props: ViewportProps) => {
  const app = useApp();
  return <PixiComponentViewport app={app} {...props} />;
};


export default Viewport;