import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import Map from "./pages/Map";
import Profile from "./pages/Profile";

type RouteType = Dapp.RouteType & { title: string };

export const routeNames = {
  // home: "/",
  map: "/",
  profile: "/Profile",
  transaction: "/transaction",
  unlock: "/unlock",
  ledger: "/ledger",
  walletconnect: "/walletconnect",
};

const routes: RouteType[] = [
  // {
  //   path: "/",
  //   title: "Home",
  //   component: Home,
  // },
  {
    path: "/",
    title: "Map",
    component: Map,
    authenticatedRoute: false,
  },
  {
    path: "/Profile",
    title: "Profile",
    component: Profile,
    authenticatedRoute: true,
  },
];

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title
      ? `${dAppName} • ${route.title}`
      : `${dAppName}`;
    return {
      path: route.path,
      authenticatedRoute: Boolean(route.authenticatedRoute),
      component: withPageTitle(
        title,
        route.component
      ) as any as React.ComponentClass<any, any>,
    };
  });

export default wrappedRoutes();
