export function computeMaxSubMatrix(M) {
    let i,j;
    let S = [];
 
    for ( var y = 0; y < 100; y++ ) {
        S[ y ] = [];
        for ( var x = 0; x < 100; x++ ) {
            S[ y ][ x ] = 0;
        }
    }
    let max_of_s, max_i, max_j;
     
    /* Set first column of S[][]*/
    for (i = 0; i < 100; i++)
        S[i][0] = `${i}x0` in M ? M[`${i}x0`] : 0;
     
    /* Set first row of S[][]*/
    for (j = 0; j < 100; j++)
        S[0][j] = `0x${j}` in M ? M[`0x${j}`] : 0;
         
    /* Construct other entries of S[][]*/
    for (i = 1; i < 100; i++) {
        for(j = 1; j < 100; j++) {
            if(`${i}x${j}` in M)
                S[i][j] = Math.min(S[i][j-1],Math.min( S[i-1][j],
                                S[i-1][j-1])) + 1;
            else
                S[i][j] = 0;
        }
    }

    /* Find the maximum entry, and indexes of maximum entry
        in S[][] */
    max_of_s = S[0][0]; max_i = 0; max_j = 0;
    for(i = 0; i < 100; i++) {
        for(j = 0; j < 100; j++) {
            if(max_of_s < S[i][j]) {
                max_of_s = S[i][j];
                max_i = i;
                max_j = j;
            }
        }            
    }
 
    max_of_s = max_of_s -1;

    return {
        x: max_i - max_of_s, y: max_j - max_of_s,
        w: max_of_s,
    };
}